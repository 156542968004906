import { useEffect } from 'react';
import { useOverlayScrollbars } from 'overlayscrollbars-react';

export const useInitializeBodyScroll = () => {
  const [initBodyOverlayScrollbars] = useOverlayScrollbars({
    defer: true
  });

  useEffect(() => {
    initBodyOverlayScrollbars(document.body);
  }, [initBodyOverlayScrollbars]);
};

export const scrollToRight = (ref: React.RefObject<any>) => {
  if (ref.current) {
    const osInstance = ref.current.osInstance();
    const elements = osInstance.elements();
    const { content, viewport } = elements;

    viewport.scrollTo({
      behavior: 'smooth',
      left: content.scrollWidth
    });
  }
};
